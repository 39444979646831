import { AlertTitle, Box, Heading, } from "@chakra-ui/react";

const DataPrivacy = () => {
    return (
        <Box bgColor="background" minH="100vh" minW="100vw" margin="auto" padding="3rem">
            <Heading>Datenschutzhinweise</Heading>
            <br></br>
            Wie nehmen den Schutz Ihrer persönlichen Daten sehr ernst und halten uns strikt an die Regeln der Datenschutzgesetze. Mit diesen Datenschutzhinweisen informieren wir Sie über die Verarbeitung Ihrer personenbezogenen Daten und Ihre Datenschutzrechte gemäß der Datenschutz-Grundverordnung (DSGVO).
            <br></br>
            <br></br>
            <Heading>Einleitung</Heading><br></br>
            <p>Die Schilling & Schäfer GbR (nachfolgend „Salzsammler Studios“), freut sich, dass Sie unsere Website besuchen.<br></br>
                Datenschutz und Datensicherheit bei der Nutzung unserer Website sind für uns sehr wichtig.<br></br>
                Im Folgenden informieren wir über die Verarbeitung personenbezogener Daten bei der Nutzung<br></br>
                <li>unserer Website https://salzsammler.de</li>
                <li>der Subdomains von salzsammler.de</li>
                <li>unserer Profile in Sozialen Medien.</li>
                Personenbezogene Daten sind alle Daten, die auf eine konkrete natürliche Person beziehbar sind, z. B. Ihr Name oder Ihre IP-Adresse.
            </p>
            <br></br>
            <b>Kontaktdaten</b><br></br>
            <p>Verantwortlicher gem. Art. 4 Abs. 7 EU-Datenschutz-Grundverordnung (DSGVO) ist:</p>
            <br></br>
            <p>Schilling & Schäfer GbR, Salzsammler Studios</p>
            <p>Neufeldstr. 6</p>
            <p>81243 München</p>
            <p>Telefon: 0176 24071386</p>
            <p>E-Mail: info@salzsammler.de</p>
            <p>Vertretungsberechtigte Gesellschafter: Nic Schilling und Ruben Schäfer</p>
            <br></br>
            <b>Umfang der Datenverarbeitung, Verarbeitungszwecke und Rechtsgrundlagen</b>
            <p>Den Umfang der Verarbeitung der Daten, Verarbeitungszwecke und Rechtsgrundlagen führen wir im Detail weiter unten aus. Als Rechtsgrundlage für eine Datenverarbeitung kommen grundsätzlich die folgenden in Betracht:</p>
            <li>Art. 6 Abs. 1 S. 1 lit. a DSGVO dient uns als Rechtsgrundlage für Verarbeitungsvorgänge, für die wir eine Einwilligung einholen.</li>
            <li>Art. 6 Abs. 1 S. 1 lit. b DSGVO ist Rechtsgrundlage, soweit die Verarbeitung personenbezogener Daten zur Erfüllung eines Vertrages erforderlich ist, z.B. wenn ein Seitenbesucher von uns ein Produkt erwirbt oder wir für ihn eine Leistung ausführen. Diese Rechtsgrundlage gilt auch für Verarbeitungen, die für vorvertragliche Maßnahmen erforderlich sind, etwa bei Anfragen zu unseren Produkten oder Leistungen.</li>
            <li>Art. 6 Abs. 1 S. 1 lit. c DSGVO findet Anwendung, wenn wir mit der Verarbeitung personenbezogener Daten eine rechtliche Verpflichtung erfüllen, wie es z.B. im Steuerrecht der Fall sein kann.</li>
            <li>Art. 6 Abs. 1 S. 1 lit. f DSGVO dient als Rechtsgrundlage, wenn wir uns zur Verarbeitung personenbezogener Daten auf berechtigte Interessen berufen können, z.B. für Cookies, die für den technischen Betrieb unserer Website erforderlich sind.</li>
            <br></br>
            <b>Datenverarbeitung außerhalb des EWR</b>
            <p>Soweit wir Daten an Dienstleister oder sonstige Dritte außerhalb des EWR übermitteln, garantieren die Sicherheit der Daten bei der Weitergabe, soweit (z.B. für Großbritannien, Kanada und Israel) vorhanden, Angemessenheitsbeschlüsse der EU-Kommission (Art. 45 Ab. 3 DSGVO).</p>
            <br></br>
            <p>Wenn kein Angemessenheitsbeschluss existiert (z.B. für die USA), sind Rechtsgrundlage für die Datenweitergabe im Regelfall, also soweit wir keinen abweichenden Hinweis geben, Standardvertragsklauseln. Diese sind ein von der EU-Kommission verabschiedetes Regelwerk und Teil des Vertrags mit dem jeweiligen Dritten. Nach Art. 46 Abs. 2 lit. b DSGVO gewährleisten sie die Sicherheit der Datenweitergabe. Viele der Anbieter haben über die Standardvertragsklauseln hinausgehende vertragliche Garantien abgegeben, die die Daten über die Standardvertragsklauseln hinaus schützen. Das sind z.B. Garantien hinsichtlich der Verschlüsselung der Daten oder hinsichtlich einer Pflicht des Dritten zum Hinweis an Betroffene, wenn Strafverfolgungsorgane auf Daten zugreifen wollen.</p>
            <br></br>
            <b>Speicherdauer</b>
            <p>Sofern nicht im Rahmen dieser Datenschutzerklärung ausdrücklich angegeben, werden die bei uns gespeicherten Daten gelöscht, sobald sie für ihre Zweckbestimmung nicht mehr erforderlich sind und der Löschung keine gesetzlichen Aufbewahrungspflichten entgegenstehen. Sofern die Daten nicht gelöscht werden, weil sie für andere und gesetzlich zulässige Zwecke erforderlich sind, wird ihre Verarbeitung eingeschränkt, d.h. die Daten werden gesperrt und nicht für andere Zwecke verarbeitet. Das gilt z.B. für Daten, die wir aus handels- oder steuerrechtlichen Gründen aufbewahren müssen.</p>
            <br></br>
            <b>Rechte der Betroffenen</b>
            <p>Betroffene haben gegenüber uns folgende Rechte hinsichtlich der sie betreffenden personenbezogenen Daten:</p>
            <li>Recht auf Auskunft</li>
            <li>Recht auf Berichtigung oder Löschung</li>
            <li>Recht auf Einschränkung der Verarbeitung</li>
            <li>Recht auf Widerspruch gegen die Verarbeitung</li>
            <li>Recht auf Datenübertragbarkeit</li>
            <li>Recht, eine erteilte Einwilligung jederzeit zu widerrufen</li>
            <p>Betroffene haben zudem das Recht, sich bei einer Datenschutz-Aufsichtsbehörde über die Verarbeitung ihrer personenbezogenen Daten zu beschweren. Kontaktdaten der Datenschutz-Aufsichtsbehörden sind unter https://www.bfdi.bund.de/DE/Service/Anschriften/Laender/Laender-node.html.</p>
            <br></br>
            <b>Pflicht zur Bereitstellung von Daten</b>
            <p>Kunden, Interessenten oder Dritte müssen uns im Rahmen einer Geschäftsbeziehung oder sonstigen Beziehung nur diejenigen personenbezogenen Daten bereitstellen, die für die Begründung, Durchführung und Beendigung der Geschäftsbeziehung oder für die sonstige Beziehung erforderlich sind oder zu deren Erhebung wir gesetzlich verpflichtet sind. Ohne diese Daten werden wir in der Regel den Abschluss eines Vertrages oder die Bereitstellung einer Leistung ablehnen müssen oder einen bestehenden Vertrag oder sonstige Beziehung nicht mehr durchführen können.</p>
            <br></br>
            <p>Pflichtangaben sind als solche gekennzeichnet.</p>
            <br></br>
            <b>Keine automatische Entscheidungsfindung im Einzelfall</b>
            <p>Zur Begründung und Durchführung einer Geschäftsbeziehung oder sonstigen Beziehung nutzen wir grundsätzlich keine vollautomatisierte Entscheidungsfindung gemäß Artikel 22 DSGVO. Sollten wir diese Verfahren in Einzelfällen einsetzen, werden wir hierüber gesondert informieren, sofern dies gesetzlich geboten ist.</p>
            <br></br>
            <b>Kontaktaufnahme</b>
            <p>Bei der Kontaktaufnahme mit uns, z.B. per E-Mail oder Telefon, werden die uns mitgeteilten Daten (z.B. Namen und E-Mail-Adressen) von uns gespeichert, um Fragen zu beantworten. Rechtsgrundlage für die Verarbeitung ist unser berechtigtes Interesse (Art. 6 Abs. 1 S. 1 lit. f DSGVO), an uns gerichtete Anfragen zu beantworten. Die in diesem Zusammenhang anfallenden Daten löschen wir, nachdem die Speicherung nicht mehr erforderlich ist, oder schränken die Verarbeitung ein, falls gesetzliche Aufbewahrungspflichten bestehen.</p>
            <br></br>
            <b>Kundenumfragen</b>
            <p>Von Zeit zu Zeit führen wir Kundenumfragen durch, um unsere Kunden und ihre Wünsche besser kennenzulernen. Dabei erheben wir die jeweils abgefragten Daten. Es ist unser berechtigtes Interesse, unsere Kunden und ihre Wünsche besser kennenzulernen, so dass Rechtsgrundlage der damit einhergehenden Datenverarbeitung Art. 6 Abs. 1 S. 1 lit f DSGVO ist. Die Daten löschen wir, wenn die Ergebnisse der Umfragen ausgewertet sind.</p>
            <br></br>
            <Heading>Datenverarbeitung auf unserer Webseite</Heading>
            <br></br>
            <b>Informatorische Nutzung der Website</b>
            <p>Bei der informatorischen Nutzung der Website, also wenn Seitenbesucher uns nicht gesondert Informationen übermitteln, erheben wir die personenbezogenen Daten, die der Browser an unseren Server übermittelt, um die Stabilität und Sicherheit unserer Website zu gewährleisten. Darin liegt unser berechtigtes Interesse, so dass Rechtsgrundlage ist Art. 6 Abs. 1 S. 1 lit. f DSGVO ist.</p>
            <p>Diese Daten sind:</p>
            <li>IP-Adresse</li>
            <li>Datum und Uhrzeit der Anfrage</li>
            <li>Zeitzonendifferenz zur Greenwich Mean Time (GMT)</li>
            <li>Inhalt der Anfrage (konkrete Seite)</li>
            <li>Zugriffsstatus/HTTP-Statuscode</li>
            <li>jeweils übertragene Datenmenge</li>
            <li>Website, von der die Anfrage kommt</li>
            <li>Browser</li>
            <li>Betriebssystem und dessen Oberfläche</li>
            <li>Sprache und Version der Browsersoftware.</li>
            <p>Diese Daten werden außerdem in Logfiles gespeichert. Sie werden gelöscht, wenn ihre Speicherung nicht mehr erforderlich ist, spätestens nach 14 Tagen.</p>
            <br></br>
            <b>Webhosing und Bereitstellung der Website</b>
            <p>Unsere Website hostet Netcup. Anbieter ist netcup GmbH, Daimlerstraße 25, 76185 Karlsruhe, Deutschland.</p>
            <p>Der Anbieter verarbeitet dabei die über die Website übermittelten personenbezogenen Daten, z.B. Inhalts-, Nutzungs-, Meta-/Kommunikationsdaten oder Kontaktdaten, in Nürnberg, Deutschland. Weitere Informationen finden sich in der Datenschutzerklärung des Anbieters unter https://www.netcup.com/de/kontakt/datenschutzerklaerung.</p>
            <p>Es ist unser berechtigtes Interesse, eine Website zur Verfügung zu stellen, so dass die Rechtsgrundlage der beschriebenen Datenverarbeitung Art. 6 Abs. 1 S. 1 lit. f DSGVO ist.
                Rechtsgrundlage der Übermittlung in ein Land außerhalb des EWR sind Standardvertragsklauseln. Die Sicherheit der in das Drittland (also einem Land außerhalb des EWR) übermittelten Daten ist durch gemäß dem Prüfverfahren nach Art. 93 Abs. 2 DSGVO erlassene Standarddatenschutzklauseln gewährleistet (Art. 46 Abs. 2 lit. c DSGVO), die wir mit dem Anbieter vereinbart haben.</p>
            <br></br>
            <Heading>Drittanbieter</Heading>
            <br></br>
            <b>Google Webfonts</b>
            <p>Wir setzen Google Webfonts für Schriftarten auf der Website ein. Der Anbieter ist Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland. Die Verarbeitung erfolgt aber nur auf unseren Servern. Wir verarbeiten Meta-/Kommunikationsdaten (z.B. Geräte-Informationen, IP-Adressen) in der EU.

                Die Rechtsgrundlage der Verarbeitung ist Art. 6 Abs. 1 S. 1 lit. a DSGVO. Die Verarbeitung erfolgt auf der Basis von Einwilligungen. Betroffene können ihre Einwilligung jederzeit widerrufen, indem sie uns z.B. unter den in unserer Datenschutzerklärung angegebenen Kontaktdaten kontaktieren. Der Widerruf berührt nicht die Rechtmäßigkeit der Verarbeitung bis zum Widerruf.

                Weitere Informationen sind in der Datenschutzerklärung des Anbieters unter https://policies.google.com/privacy?hl=de abrufbar.</p>
            <br></br>
            <b>Google Analytics</b>
            <p>Wir setzen Google Analytics zur Analyse ein. Der Anbieter ist Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Dublin, Irland. Der Anbieter verarbeitet Nutzungsdaten (z.B. besuchte Webseiten, Interesse an Inhalten, Zugriffszeiten) und Meta-/Kommunikationsdaten (z.B. Geräte-Informationen, IP-Adressen) in den USA.

                Die Rechtsgrundlage der Verarbeitung ist Art. 6 Abs. 1 S. 1 lit. a DSGVO. Die Verarbeitung erfolgt auf der Basis von Einwilligungen. Betroffene können ihre Einwilligung jederzeit widerrufen, indem sie uns z.B. unter den in unserer Datenschutzerklärung angegebenen Kontaktdaten kontaktieren. Der Widerruf berührt nicht die Rechtmäßigkeit der Verarbeitung bis zum Widerruf.

                Rechtsgrundlage der Übermittlung in ein Land außerhalb des EWR sind Standardvertragsklauseln. Die Sicherheit der in das Drittland (also einem Land außerhalb des EWR) übermittelten Daten ist durch gemäß dem Prüfverfahren nach Art. 93 Abs. 2 DSGVO erlassene Standarddatenschutzklauseln gewährleistet (Art. 46 Abs. 2 lit. c DSGVO), die wir mit dem Anbieter vereinbart haben.

                Die Daten werden gelöscht, wenn der Zweck ihrer Erhebung entfallen ist und keine Aufbewahrungspflicht entgegensteht. Weitere Informationen sind in der Datenschutzerklärung des Anbieters unter https://policies.google.com/privacy?hl=de abrufbar.</p>
            <br></br>
            <Heading>Datenverarbeitung auf Social-Media Plattformen</Heading>
            <br></br>
            <p>Wir sind in Social Media-Netzwerken vertreten, um dort unsere Organisation und unsere Leistungen vorzustellen. Die Betreiber dieser Netzwerke verarbeiten Daten ihrer Nutzer regelmäßig zu Werbezwecken. Unter anderem erstellen sie aus ihrem Onlineverhalten Nutzerprofile, die beispielsweise dazu verwendet werden, um auf den Seiten der Netzwerke und auch sonst im Internet Werbung zu zeigen, die den Interessen der Nutzer entspricht. Dazu speichern die Betreiber der Netzwerke Informationen zu dem Nutzungsverhalten in Cookies auf dem Rechner der Nutzer. Es ist außerdem nicht auszuschließen, dass die Betreiber diese Informationen mit weiteren Daten zusammenführen. Weitere Informationen sowie Hinweise, wie Nutzer der Verarbeitung durch die Seitenbetreiber widersprechen können, erhalten Nutzer in den unten aufgeführten Datenschutzerklärungen der jeweiligen Betreiber. Es kann auch sein, dass die Betreiber oder ihre Server in Nicht-EU-Staaten sitzen, so dass sie Daten dort verarbeiten. Hierdurch können sich für die Nutzer Risiken ergeben, z.B. weil die Durchsetzung ihrer Rechte erschwert wird oder staatliche Stellen Zugriff auf die Daten nehmen.

Wenn Nutzer der Netzwerke mit uns über unsere Profile in Kontakt treten, verarbeiten wir die uns mitgeteilten Daten, um die Anfragen zu beantworten. Darin liegt unser berechtigtes Interesse, so dass Rechtsgrundlage Art. 6 Abs. 1 S. 1 lit. f DSGVO ist.</p>
            <br></br>
            <b>Instagram</b>
            <p>Wir unterhalten ein Profil auf Instagram. Betreiber ist Facebook Ireland Ltd., 4 Grand Canal Square, Grand Canal Harbour, Dublin 2, Irland. Die Datenschutzerklärung ist hier abrufbar: https://help.instagram.com/519522125107875.</p>
            <br></br>
            <b>YouTube</b>
            <p>Wir unterhalten ein Profil auf YouTube. Betreiber ist Google Ireland Limited Gordon House, Barrow Street Dublin 4. Ireland. Die Datenschutzerklärung ist hier abrufbar: https://policies.google.com/privacy?hl=de.</p>
            <br></br>
            <b>LinkedIn</b>
            <p>Wir unterhalten ein Profil auf LinkedIn. Betreiber ist LinkedIn Ireland Unlimited Company, Wilton Place, Dublin 2, Irland. Die Datenschutzerklärung ist hier abrufbar: https://www.linkedin.com/legal/privacy-policy?_l=de_DE. Eine Möglichkeit, der Datenverarbeitung zu widersprechen, ergibt sich über die Einstellungen für Werbeanzeigen: https://www.linkedin.com/psettings/guest-controls/retargeting-opt-out.</p>
            <br></br>
            <Heading>Änderungen dieser Datenschutzerklärung</Heading>
            <p>Wir behalten uns das Recht vor, diese Datenschutzerklärung mit Wirkung für die Zukunft zu ändern. Eine aktuelle Version ist jeweils hier verfügbar.</p>
            <br></br>
            <Heading>Fragen und Kommentare</Heading>
            <p>Für Fragen oder Kommentare bezüglich dieser Datenschutzerklärung stehen wir gern unter den oben angegebenen Kontaktdaten zur Verfügung.</p>
        </Box>
    );
}

export default DataPrivacy;